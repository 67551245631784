<template>
    <div>
        <div style="width:100%;height:60px;background-color: #fff;line-height: 60px;">
            <p style="padding-left:20px;font-weight: 600;font-size: 18px;">系统实训统计</p>
        </div>
        <div style="width:100%;height:60px;background:#fff;margin-top: 10px;display: flex;justify-content:start;align-items: center;">
            <el-select v-model="Exper" class="m-2" @change="Experchange" clearable placeholder="系统" size="large" style="margin-left: 10px;">
                <el-option
                    v-for="item in selectExper"
                    :key="item.id"
                    :label="item.systemExperienceName"
                    :value="item.id"
                />
            </el-select>
       </div>
       <div style="width:100%;height:100px;margin-top: 10px;display: flex;justify-content:space-between;align-items: center;">
        <div style="width:24%;background-color: #fff;height: 100px;display: flex;justify-content: center;align-items: center; line-height: 30px;">
            <img src="@/assets/img/courseTechingimg/kechengshixun.png" alt="">
            <div style="margin-left: 10px;">
                <p style="color: #76C8F6; font-size: 18px;">{{ExperienceStatistics?.allCount}}</p>
                <p style="font-size: 14px;">使用人数</p>
            </div>
        </div>
        <div style="width:24%;background-color: #fff;height: 100px;display: flex;justify-content: center;align-items: center; line-height: 30px;">
            <img src="@/assets/img/courseTechingimg/kechengshixun.png" alt="">
            <div style="margin-left: 10px;">
                <p style="color: #76C8F6; font-size: 18px;">{{ExperienceStatistics?.allCount}}</p>
                <p style="font-size: 14px;">成果提交人数</p>
            </div>
        </div>
        <div style="width:24%;background-color: #fff;height: 100px;display: flex;justify-content: center;align-items: center; line-height: 30px;">
            <img src="@/assets/img/courseTechingimg/kechengshixun.png" alt="">
            <div style="margin-left: 10px;">
                <p style="color: #76C8F6; font-size: 18px;">{{ExperienceStatistics?ExperienceStatistics.averageRightRate+'%':''}}</p>
                <p style="font-size: 14px;">成果平均正确率</p>
            </div>
        </div>
        <div style="width:24%;background-color: #fff;height: 100px;display: flex;justify-content: center;align-items: center; line-height: 30px;">
            <img src="@/assets/img/courseTechingimg/kechengshixun.png" alt="">
            <div style="margin-left: 10px;">
                <p style="color: #76C8F6; font-size: 18px;">{{ExperienceStatistics?ExperienceStatistics.maxRightRate+'%':''}}</p>
                <p style="font-size: 14px;">成果最高正确率</p>
            </div>
        </div>
       
       </div>
       <div  style="width:100%;height:350px;background-color: #fff; margin-top: 10px;">
       
        
            <div id="main4" style="width: 100%;height: 100%;"></div>
        
       </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { reactive, toRefs, onMounted, onUnmounted, nextTick ,onBeforeUnmount} from "vue";
// import router from "../../router/index.js";
import {F_Get_subscribeCourseForStudentId} from '@/js/teachingSquare'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {courseData} from "@/js/caseApi";
import {f_get_systemExperienceStatistics,get_select_systemExperience} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';
export default {
    setup(prop,context) {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            baseUrl:baseUrl,
            userId:getStorage('userId'),
            ExperienceStatistics:{},
            Exper:'',
            selectExper:[],
        })
        const methods = {
            //获取系统实训统计数据
            getExperienceStatistics() {
                let datas = {
                    systemExperienceId: state.Exper,
                }
                console.log(datas);
                f_get_systemExperienceStatistics(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log(res.data.data, '考评中心--系统实训统计');
                        state.ExperienceStatistics = res.data.data
                    } else {
                        ElMessage.error(res.data.message)
                    }
                     methods.getEchartsFour()
                })
            },
            //获取系统下拉
            getselectExper(){
                let datas = {
                    systemExperienceName: null,
                }
                get_select_systemExperience(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log(res.data.data, '系统下拉');
                        let allData=[{                                 
                            id:"",
                            systemExperienceName:"全部"
                        }]
                        state.selectExper = allData.concat(res.data.data)
                    } else {
                        ElMessage.error(res.data.message)
                    }
                   
                })
            },

            //下拉切换触发
            Experchange(data){
                methods.getExperienceStatistics();
            },



            classDetail() {
                router.push('/home/classDetails');
            },
            fanhui() {
                context.emit("fanhui", 3)
            },

            // 柱状图
            getEchartsFour() {
                var chartDom = document.getElementById('main4');
                var myChart = echarts.init(chartDom);
                var option;

                option = {
                    tooltip: {
                        trigger: 'axis',
                        transitionDuration: 0,
                        axisPointer: {
                            type: 'shadow',
                        }
                    },
                    title: {
                        text: '',
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '2%',
                        top: '15%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['20%以下', '20%-39%', '40%-59%', '60%-79%', '80%-100%'],
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLine: {
                                show: false,  //隐藏x轴线 

                                lineStyle: {
                                    color: "#fff",
                                },
                            },
                            axisLabel: {//y轴文字的配置
                                textStyle: {
                                    color: "#000",
                                    margin: 15
                                },
                            },
                        }
                    ],
                    yAxis:
                    {
                        type: 'value',
                        name: '人',
                        nameGap: 6,
                        splitNumber: 3,
                        minInterval:1,
                        nameTextStyle: {
                            color: "#000",
                            nameLocation: "end",
                        },
                        axisLine: {
                            show: true,  //隐藏y轴线 

                            lineStyle: {
                                color: "#ccc",
                            },
                        },
                        axisLabel: {//y轴文字的配置
                            textStyle: {
                                color: "#000",
                            },
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "bar",
                            barCategoryGap: "40%",
                            barWidth: 25,
                            barMinHeight: 1,
                            data: [state.ExperienceStatistics?.level1, state.ExperienceStatistics?.level2, state.ExperienceStatistics?.level3, state.ExperienceStatistics?.level4, state.ExperienceStatistics?.level5],
                            itemStyle: {
                                barBorderRadius: [3, 3, 0, 0],
                                opacity: 1,
                                color: {//设置渐变颜色
                                    type: "linear",
                                    //x 和 y 表示起始的坐标，x2 和 y2 表示终点坐标, 所以也就是向下渐变
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [

                                        {
                                            offset: 0.3,
                                            color: "rgba(172, 182, 229, 1)", // 30% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(134, 253, 232, 1)", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },

                        }
                    ]
                };

                option && myChart.setOption(option);
                console.log('石星雨');
                window.addEventListener("resize", function () {
                    // 让图表调用 resize这个方法
                    myChart.resize();
                });
            },

            destoryChart(){
                echarts.init(document.getElementById('main4')).dispose()
            }
        }
        onMounted(() => {
            methods.getselectExper();
            methods.getExperienceStatistics();//获取系统实训统计数据

        })
    return {
      ...toRefs(state),
      ...methods,
    };
    },
    components:{}
}
</script>
<style lang="scss" scoped>
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
</style>