<template>
  <div id="courseTeaching">

    <div class="i-wrap">
      <div class="banner-c">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in bList" :key="i">
              <img style="cursor:pointer" :src="`${baseUrl}/${item.file}`" @click="GObannerItem(item)" alt="" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>

      <div class="conentTeacher">
        <div style="width:12%;height:600px;background:#fff">
          <div style="width:100%;height:10%;text-align:center;line-height:50px;" v-for="(item, index) in dataList"
            :key="index">
            <p :class="titleIndex == index ? 'titleClass' : ''" style="cursor: pointer;" @click="titleClick(index)">{{
              item.title }}</p>
          </div>
        </div>
        <!-- 考试 -->
        <div style="width:87%;height:600px;" v-if="titleIndex == 0" :key="titleIndex">
          <starting-sta v-if="titleIndex == 0"></starting-sta>
        </div>
        <!-- 课程实训 -->
        <div style="width:87%;height:600px;" v-if="titleIndex == 1" :key="titleIndex">
          <cour-setindex v-if="titleIndex == 1" ref="chart1"></cour-setindex>
        </div>
        <!-- 系统实训 -->
        <div style="width:87%;height:600px;" v-if="titleIndex == 2" :key="titleIndex">
          <practical-traing v-if="titleIndex == 2" ref="chart2"></practical-traing>
        </div>
      </div>
    </div>
    <el-dialog title="成绩查询" v-model="dialogVisible" width="15%" :modal="false" :before-close="handleClose">
      <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
        <el-form-item label="学生姓名：" style="margin-bottom: 0px;">
          <span>王一</span>
        </el-form-item>
        <el-form-item label="学生考号：" style="margin-bottom: 0px;">
          <span>23001001</span>
        </el-form-item>
        <el-form-item label="学科：" style="margin-bottom: 0px;">
          <span>建筑力学</span>
        </el-form-item>
        <el-form-item label="考试名称：" style="margin-bottom: 0px;">
          <span>2023年上半年期末考试</span>
        </el-form-item>
        <el-form-item label="是否通过：" style="margin-bottom: 0px;">
          <span style="color: #2ACE5C;">通过</span>
        </el-form-item>
        <el-form-item label="分数：" style="margin-bottom: 0px;">
          <span style="color: #76C8F6;">89</span>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-top: 20px;">
        <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;"
          @click="handleClose">返回</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed, watch, nextTick } from "vue";
import Swiper from "swiper"; // 引入库
import "swiper/css/swiper.css"; // 引入样式文件，注意5和6版本的样式文件不一致
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import { backUrl } from "@/js/util"
import * as echarts from 'echarts';
import startingSta from './startingStatistics.vue'
import courSetindex from './courseTrainingIndex.vue'
import practicalTraing from './practicalTrainingIndex'
import { baseUrl } from '@/js/util.js'
import { ElMessage } from 'element-plus';
import { F_Get_shufflingPictureList } from "@/js/homeListApi.js"

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      bList: [
        // {
        //   pictureUrl:require('@/assets/img/index/bannerlunbo.png')
        // }
      ],
      baseUrl: baseUrl,
      userId: getStorage('userId'),
      searchValue: '',
      screenItemIndex: 0,
      value1: '',
      value2: '',
      options: [],
      form: {
        name: '',
        num: '',
        discipline: '',
        examName: '',
      },
      titleIndex: getStorage("titleIndexKao") ? getStorage("titleIndexKao") : 0,
      dataList: [
        {
          title: '考试'
        }, {
          title: '课程实训'
        }, {
          title: '系统实训'
        }
      ],
      dialogVisible: false,
      ifautoplay: false,
      ifloop: false,
      chart1: null,
      chart2: null

    })
    const methods = {
      titleClick(index) {
        state.titleIndex = index;
        if (state.titleIndex == 1) {
          state.chart2.destoryChart()
        }else if (state.titleIndex == 2) {
          state.chart1.destoryChart()
        }
        setStorage("titleIndexKao", state.titleIndex)

      },
      GObannerItem(item) {
        if (item.link) {
            window.open(item.link)
        }
      },

      //1、首页轮播 2、课程实训轮播 3、考评中心轮播
      // 获取轮播图
      async getRotationImg() {
        let res = await F_Get_shufflingPictureList({ homeBasicType: 3 })
        if (res.data.code == 1) {
          state.bList = res.data.data;
          console.log(state.bList);
          if (state.bList.length > 1) {
            state.ifautoplay = true
            state.ifloop = true
          } else if (state.bList.length <= 1) {
            state.ifautoplay = false
            state.ifloop = false
          }
          nextTick(() => { initSwiper(); })

        }
      },

      screenName(index) {
        state.screenItemIndex = index;
      },
      rowClick(item) {
        router.push('/home/systemDetails')
      },
      queryClick() {
        state.dialogVisible = true;
      },
      handleClose() {
        state.dialogVisible = false;
      },
    }
    const initSwiper = () => {
      new Swiper(".swiper-container", {
        slidesPerView: 'auto',
        spaceBetween: 30,
        speed: 3000,
        loop: state.ifloop,
        autoplay: state.ifautoplay, //自动切换

        autoplay: {
          delay: 3000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

    };
    onMounted(() => {
      methods.getRotationImg();
    })
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: { startingSta, courSetindex, practicalTraing }
}
</script>

<style  lang="scss" scoped>
#courseTeaching {
  height: 100%;
  position: relative;
  background: #F7F8FA;

  .i-wrap {
    width: 66.5%;
    height: 100%;
    margin: 0 auto;

    .banner-c {
      width: 100%;
      height: 110px;
      margin-top: 10px;

      //margin-bottom: 20px;
      .swiper-container {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .bltext {
          position: absolute;
          bottom: 0;
          background: #00000080;
          width: 100%;
          font-size: 14px;
          //padding: 10px;
          color: #fff;
        }
      }
    }

    .echarts {
      width: 100%;
      height: 800px;
      max-height: 800px;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .echartsData {
      width: 33%;
      height: 49%;
      background: #fff;
    }

    .conentTeacher {
      width: 100%;
      height: 600px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }

    .titleClass {
      color: #76C8F6;
    }
  }

  :deep {
    .swiper-pagination-bullets {
      // bottom: 8px;
      // left: auto;
      // width: auto;
      // right: 30px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      box-sizing: border-box;
      border: 1px solid #fff;
      opacity: 1;
      background: transparent;
      margin: 0 10px;

      &.swiper-pagination-bullet-active {
        width: 25px;
        height: 8px;
        border-radius: 8px;
        box-sizing: border-box;
        border: 1px solid #fff;
        opacity: 1;
        background: #fff;
        margin: 0 10px;
      }
    }
  }

  .dList {
    // padding: 30px 0 0 0;
    width: 100%;
    margin: 0 auto;

    .dtoll {
      width: 78px;
    }

    .oneLine2 {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      // justify-content: space-between;
      .doll {
        width: 980px;
        height: 305px;
        padding: 20px 0;
        box-sizing: border-box;
        background: #fff;
        overflow: hidden;

        .d-title {
          display: flex;
          justify-content: space-between;

          .dttext {
            font-size: 16px;
            font-weight: bold;
            color: #0200F9;
          }

          .mb {
            font-size: 12px;
            color: #666666;
            cursor: pointer;
          }

          margin: 0 0 15px;
        }

        .pl-c {
          display: flex;

          .pco {

            // width: 20%;
            // margin: 0 5% 0 0;
            &:last-child {
              margin: 0;
            }

            &:nth-child(4) {
              // display: none;
            }

            .img-c {
              // height: 187px;
              // box-sizing: border-box;
              // border: 1px solid #eee;
              width: 224px;
              height: 168px;
              background: #ffffff;
              border: 1px solid #e2e2e2;
              margin-right: 21px;
              box-sizing: border-box;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .pcotext {
              color: #333333;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }

          .pco:hover {
            cursor: pointer;
            /* transform属性 */
            transform: translate(0, -10px);
            transition-duration: 500ms;

            .pcotext {
              color: #0200F9;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }
        }
      }

      .dolr {
        height: 235px;
        width: 220px;
        padding: 20px;
        box-sizing: border-box;
        background: #fff;

        .dolr-titil {
          font-size: 16px;
          font-weight: bold;
          color: #0200F9;
          margin: 0 0 4px;
        }

        .dolr-tlist {
          .dtoneline {
            display: flex;
            justify-content: space-between;
            color: #333333;
            font-size: 14px;
            line-height: 32px;
          }
        }
      }
    }

    .oneLine {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      // justify-content: space-between;
      .doll {
        width: 980px;
        height: 305px;
        padding: 20px 0;
        box-sizing: border-box;
        background: #EBEBFF;
        overflow: hidden;

        .d-title {
          display: flex;
          justify-content: space-between;

          .dttext {
            font-size: 16px;
            font-weight: bold;
            color: #0200F9
          }

          .mb {
            font-size: 12px;
            color: #666666;
            cursor: pointer;
          }

          margin: 0 0 15px;
        }

        .pl-c {
          display: flex;

          .pco {

            // width: 20%;
            // margin: 0 5% 0 0;
            &:last-child {
              margin: 0;
            }

            .img-c {
              // height: 187px;
              // box-sizing: border-box;
              // border: 1px solid #eee;
              width: 224px;
              height: 168px;
              background: #ffffff;
              border: 1px solid #e2e2e2;
              margin-right: 21px;
              box-sizing: border-box;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .pcotext {
              color: #333333;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }

          .pco:hover {
            cursor: pointer;
            /* transform属性 */
            transform: translate(0, -10px);
            transition-duration: 500ms;

            .pcotext {
              color: #0200F9;
              font-size: 14px;
              padding: 15px 0 10px;
              font-weight: bold;
              width: 210px;
            }
          }
        }
      }

      .dolr {
        height: 235px;
        width: 220px;
        padding: 20px;
        box-sizing: border-box;
        background: #EBEBFF;

        .dolr-titil {
          font-size: 16px;
          font-weight: bold;
          color: #0200F9;
          margin: 0 0 4px;
        }

        .dolr-tlist {
          .dtoneline {
            display: flex;
            justify-content: space-between;
            color: #333333;
            font-size: 14px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .inputIcon {
    display: flex;
    justify-content: center;
    position: relative;

  }

  .inputIcon img {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
    cursor: pointer;
  }

  .screen {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;

    .screenClass {
      float: left;
      font-size: 14px;
      padding: 10px 20px 10px 20px;
      border-radius: 8px;
      margin-left: 20px;
      cursor: pointer;
    }

    .active {
      background: #D3EDFC;
      color: #76C8F6;
    }
  }

  .ulList::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background-color: transparent;
  }

  .ulList::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
  }

  .ulList::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px transparent;
    border-radius: 10px;
    background-color: transparent;
  }

  .dataList {
    width: 100%;
    // height: 800px;
    // background: #fff;
    margin-top: 10px;
  }

  .dataListRow {
    width: 100%;
    height: 180px;
    background: #fff;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;

  }

  .dataListRow:hover {
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.06);
  }

  .el-pagination {
    background: none
  }
}

:deep .elform .el-input__inner {
  color: #666;
  background: #fff;
  border-radius: 50px !important;
  border: 1px solid #E1E1E1;
}


@media screen and (min-width: 1600px) {
  #index {
    .i-wrap {

      // width: 1400px;
      .banner-c {
        height: 520px;
        margin-bottom: 20px;
      }
    }

    .dList {
      .dtoll {
        width: 78px;
      }

      .oneLine,
      .oneLine2 {
        width: 1400px;

        .doll {
          height: 305px;
          width: 1200px;
          overflow: hidden;

          .pl-c {

            .pco {
              &:nth-child(4) {
                display: inline-block;
              }

              .img-c {
                margin-right: 18px;
              }
            }

            .pco:hover {
              cursor: pointer;
              /* transform属性 */
              transform: translate(0, -10px);
              transition-duration: 300ms;

              .pcotext {
                color: #0200F9;
                font-size: 14px;
                padding: 15px 0 10px;
                font-weight: bold;
                width: 210px;
              }
            }
          }
        }

        .dolr {
          height: 274px;
          width: 200px;
          padding: 20px 0px 20px 18px;

          .dolr-titil {
            margin: 0 0 4.5px;
          }

          .dolr-tlist {
            .dtoneline {
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}</style>