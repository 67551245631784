import axios from 'axios';
import {baseUrl} from '@/js/util.js'

export const caseSharingPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_caseSharingPageList`,
        params:datas,
        headers:{'isFrontEnt':true}
    })
}


// 获取专业的列表
export const majorList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_search_majorList`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}

// 获取各个阶段的课程 阶段（1、智慧建造、2数字设计、3、智慧施工、4、智慧运维）
export const coursePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_coursePageList`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}
// 课程详情
export const courseData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_courseData`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}

// 添加/取消订阅课程
export const subscribeCourse=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_subscribeCourse`,
        data:datas,
    })
}

// 添加播放记录
export const playRecording=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_playRecording`,
        data:datas,
    })
}

// 获取指定课程内容的播放进度
export const playRecordingData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_playRecordingData`,
        params:datas,
    })
}


// 获取实训具体内容
export const trainingContent=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_trainingContent`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}
// 添加实训记录
export const trainingRecord=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_trainingRecord`,
        data:datas,
    })
}

// 历史
export const trainingRecordsForCourseContentId=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_trainingRecordsForCourseContentId`,
        params:datas,
     
    })
}

// 获取实训说明列表
export const trainingIntroducedList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/B_videoTraining/B_Get_trainingIntroducedList`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}

// 浏览记录
export const browsingHistory=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_browsingHistory`,
        data:datas,
    })
}

// 预览文件
export const Show_File=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/UploadFile/Show_File`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}

// 获取知识分类列表
export const F_Get_knowledgeCategoryList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_knowledgeCategoryList`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}

// 获取实训案例列表
export const F_Get_traingCaseForId=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_traingCaseForId`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}

// 获取弹窗内的实训案例
export const F_Get_traingCaseForPhase=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_traingCaseForPhase`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}

// 获取知识点
export const F_Get_knowledgePointForCategoryId=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_knowledgePointForCategoryId`,
        params:datas,
        headers:{"isFrontEnt":true}
    })
}



// 获取用户课程实训分页数据
export const F_Get_digitalTrainingPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_digitalTrainingPageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}