<template>
    <div>
        <div style="width:100%;height:60px;background-color: #fff;line-height: 60px;">
            <p style="padding-left:20px;font-weight: 600;font-size: 18px;">期末考试统计</p>
        </div>
        <div style="width:100%;height:60px;background:#fff;margin-top: 10px;display: flex;justify-content:start;align-items: center;">
            <el-select v-model="Year" class="m-2" clearable placeholder="年度" size="large" style="margin-left: 10px;">
                <el-option
                  v-for="item in selectYear"
                  :key="item"
                  :label="item"
                  :value="item"
                />
            </el-select>
            <el-select v-model="grade" class="m-2" clearable placeholder="年级" size="large"  style="margin-left: 10px;">
                <el-option
                  v-for="item in selectgrade"
                  :key="item.id"
                  :label="item.gradeName"
                  :value="item.id"
                />
            </el-select>
            <el-select v-model="subject" class="m-2" clearable placeholder="科目" size="large"  style="margin-left: 10px;">
                <el-option
                  v-for="item in selectsubject"
                  :key="item.id"
                  :label="item.subjectName"
                  :value="item.id"
                />
            </el-select>
            <el-select v-model="examclass" class="m-2" clearable placeholder="全部已考班级" size="large"  style="margin-left: 10px;">
                <el-option
                  v-for="item in selectclass"
                  :key="item.id"
                  :label="item.className"
                  :value="item.id"
                />
            </el-select>
            <div>
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="Querying">查询</el-button>
            </div>
       </div>
       <div style="width:100%;height:80px;background:#fff;margin-top: 10px;display: flex;justify-content:space-around;align-items: center;">
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #2ACE5C;font-size: 18px;">{{examinationStatis?.passCount}}</p>
            <p style="font-size: 14px;">通过人数</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: red;font-size: 18px;">{{examinationStatis?.noPassCount}}</p>
            <p style="font-size: 14px;">未通过人数</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #2ACE5C;font-size: 18px;">{{examinationStatis?.passingRate}}</p>
            <p style="font-size: 14px;">通过率</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #76C8F6;font-size: 18px;">{{examinationStatis?.planJionCount }}</p>
            <p style="font-size: 14px;">应参加人数</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #76C8F6;font-size: 18px;">{{examinationStatis?.practicalJionCount}}</p>
            <p style="font-size: 14px;">已参加人数</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #FC1515;font-size: 18px;">{{examinationStatis?.notJionCount}}</p>
            <p style="font-size: 14px;">缺考人数</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #76C8F6;font-size: 18px;">{{examinationStatis?.maxScore}}</p>
            <p style="font-size: 14px;">最高分</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #76C8F6;font-size: 18px;">{{examinationStatis?.averageScore}}</p>
            <p style="font-size: 14px;">平均分</p>
        </div>
        <div style="text-align: center;line-height: 30px;">
            <p style="color: #FC1515;font-size: 18px;">{{examinationStatis?.minScore}}</p>
            <p style="font-size: 14px;">最低分</p>
        </div>
       </div>
       <div  style="width:100%;height:360px;margin-top: 10px;display: flex;justify-content:space-between;align-items: center;">
        <div style="width: 50%;height: 380px;background-color: #fff;">
            <div id="main1" style="width: 100%;height: 100%;"></div>
        </div>
        <div style="width: 49.5%;height: 380px;background-color: #fff;">
            <div id="main4" style="width: 100%;height: 100%;"></div>
        </div>
       </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import {F_Get_subscribeCourseForStudentId} from '@/js/teachingSquare'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {courseData} from "@/js/caseApi";
import * as echarts from 'echarts';
import {f_get_examinationStatistics,get_select_examinationYear,get_select_gradeList,get_select_subjectList,get_select_classNumList} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';
export default {
    setup(prop, context) {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            baseUrl:baseUrl,
            userId:getStorage('userId'),
            Year: '',//年度
            selectYear:[],//年度
            grade:'',//年级
            selectgrade:[],//年级
            subject:'',//科目
            selectsubject:[],//科目
            examclass:'',//班级
            selectclass:[],//班级
            examinationStatis:{},//考评中心 考试统计


        })
        const methods = {
            //获取考评中心 考试统计
            getexaminationStatistics(){
                let datas = {
                    year:state.Year,//年度 可为空
                    gradeId:state.grade,//年级 可为空
                    subjectId:state.subject,//课程 可为空
                    classNumId:state.examclass,//班级 可为空
                }
                console.log(datas);
                f_get_examinationStatistics(datas).then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data,'考评中心--考试统计');
                        state.examinationStatis = res.data.data
                    }else{
                        ElMessage.error(res.data.message)
                    }
                     methods.getEchartList()
                })
            },
            //获取考试年度
            getselectYear(){
                get_select_examinationYear().then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data,'考试年度');
                        state.selectYear = res.data.data;
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取年级下拉列表搜索框
            getselectgrade(){
                get_select_gradeList().then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data,'考试年级');
                        state.selectgrade = res.data.data;
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取科目下拉列表搜索框
            getselectsubject(){
                get_select_subjectList().then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data,'考试科目');
                        state.selectsubject = res.data.data;
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取班级下拉列表搜索框
            getselectclass(){
                get_select_classNumList().then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data,'考试班级');
                        state.selectclass = res.data.data;
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },

            //点击查询
            Querying(){
                methods.getexaminationStatistics();
               
            },

            classDetail() {
                router.push('/home/classDetails');
            },
            fanhui() {
                context.emit("fanhui", 3)
            },
            //   圆环图
            getEchartsOne() {
                var chartDom = document.getElementById('main1');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        bottom: '2%',
                        left: 'center'
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,

                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 40,
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                { value: state.examinationStatis?.passCount, name: '通过', itemStyle: { color: "#00C78C" } },
                                { value: state.examinationStatis?.noPassCount, name: '未通过', itemStyle: { color: "#FF6347" } }
                            ]
                        }
                    ]
                };

                option && myChart.setOption(option);
                window.addEventListener("resize", function () {
                    // 让图表调用 resize这个方法
                    myChart.resize();
                });
            },
            // 柱状图
            getEchartsFour() {
                var chartDom = document.getElementById('main4');
                var myChart = echarts.init(chartDom);
                var option;

                option = {
                    tooltip: {
                        trigger: 'axis',
                        transitionDuration: 0,
                        axisPointer: {
                            type: 'shadow',
                        }
                    },
                    title: {
                        text: '',
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '2%',
                        top: '15%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['0-20分', '20-40分', '40-60分', '60-80分', '80-~'],
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLine: {
                                show: false,  //隐藏x轴线 

                                lineStyle: {
                                    color: "#fff",
                                },
                            },
                            axisLabel: {//y轴文字的配置
                                textStyle: {
                                    color: "#000",
                                    margin: 15
                                },
                            },
                        }
                    ],
                    yAxis:
                    {
                        type: 'value',
                        name: '人',
                        nameGap: 6,
                        splitNumber: 3,
                        nameTextStyle: {
                            color: "#000",
                            nameLocation: "end",
                        },
                        axisLine: {
                            show: true,  //隐藏y轴线 

                            lineStyle: {
                                color: "#ccc",
                            },
                        },
                        axisLabel: {//y轴文字的配置
                            textStyle: {
                                color: "#000",
                            },
                        },
                    },
                    series: [
                        {
                            name: "",
                            type: "bar",
                            barCategoryGap: "40%",
                            barWidth: 25,
                            data: [state.examinationStatis?.level1, state.examinationStatis?.level2, state.examinationStatis?.level3, state.examinationStatis?.level4, state.examinationStatis?.level5],
                            itemStyle: {
                                barBorderRadius: [3, 3, 0, 0],
                                opacity: 1,
                                color: {//设置渐变颜色
                                    type: "linear",
                                    //x 和 y 表示起始的坐标，x2 和 y2 表示终点坐标, 所以也就是向下渐变
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [

                                        {
                                            offset: 0.3,
                                            color: "rgba(172, 182, 229, 1)", // 30% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(134, 253, 232, 1)", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },

                        }
                    ]
                };

                option && myChart.setOption(option);
                window.addEventListener("resize", function () {
                    // 让图表调用 resize这个方法
                    myChart.resize();
                });
            },
            getEchartList(){
                methods.getEchartsOne();
                methods.getEchartsFour();
            },
        }
        onMounted(() => {
            methods.getselectYear();//考试年度
            methods.getselectgrade();//考试年级
            methods.getselectsubject();//考试科目
            methods.getselectclass();//考试班级

            methods.getexaminationStatistics();//获取考评中心 考试统计
        })
        return {
            ...toRefs(state),
            ...methods,
        };
    },
    components: {}
}
</script>
<style lang="scss" scoped>
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
</style>